
  .Container{
    background-color:#52330D;
    color:white;
    display:flex;
    justify-content:center;
    align-Items:center;
    width:100%;
    flex-direction:column;
  }

  .Title{
    font-family:Libre Baskerville;
    font-size:54px;
  }

  .Container_1{
    width:50%;
    height:28rem;
    margin-top:3rem;
    display:flex; 
  }

  .Container_1_Image{
    width:25rem;
    height:25rem;
  }

  .Container_1_Text{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding-Left:2rem;
  }

  .Container_1_Text_Header{
    font-size:42px;
    font-family:normal normal 900 42px/57px Avenir;
  }

  .Container_1_Text_Body{
    font-size:18px;
    margin-top:2rem;
    font-family:Avenir LT Std 65 Medium;
    font-weight:600;
  }

  .Container_1_Text_Button{
    display:flex;
    justify-content:space-evenly;
     margin-top:4rem;
  }

  .Container_2{
    width:50%;
    height:26rem;
    margin-top:2rem;
    display:flex; 
  }

  .Container_2_Text{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding-right:2rem;
  }

  .Container_2_Text_Header{
    font-size:42px;
    font-family:Avenir LT Std 65 Medium;
    font-weight:600;
  }

  .Container_2_Text_Body{
    font-size:18px;
    margin-top:2rem;
    font-family:Avenir LT Std 65 Medium;
    font-weight:600;
  }

  .Container_2_Image{
    width:25rem;
    height:25rem;
  }

  .Container_3{
    width:50%;
    height:26rem;
    margin-top:2rem;
    display:flex; 
  }

  @media screen and (max-width: 1050px){

    
  .Container{
    background-color:#52330D;
    color:white;
    display:flex;
    justify-content:flex-start;
    align-Items:center;
    width:100%;
    flex-direction:column;
  }
  
    .Container_1{
      width:100%;
      height:auto;
       display:flex; 
       flex-direction: column;
       align-items: center;
    }

    .Container_1_Image{
      width:20rem;
      height:20rem;
      object-fit: contain;
    }  

    .Container_1_Text{
      display:flex;
      flex-direction:column;
      align-items:flex-start;
      text-align: left;      
      padding:0% 10% 0% 15%;

    }

    .Container_1_Text_Header{
      margin-top: 1rem;
      font-size:32px;
      font-family:normal normal 900 42px/57px Avenir;
    }

    .Container_1_Text_Body{
      font-size:12px;
      margin-top:1rem;
      font-family:Avenir LT Std 65 Medium;
      font-weight:600;
    }

    .Container_1_Text_Button{
      display:flex;
      justify-content:space-evenly;
       margin-top:2rem;
    }

    .Container_2{
      width:100%;
      height:auto;
      display:flex; 
      flex-direction: column-reverse;
      align-items: center;
    }
  
    .Container_2_Text{
      display:flex;
      flex-direction:column;
      text-align: left;      
      padding:0% 10% 0% 15%;
    }

    .Container_2_Text_Header{
      font-size:32px;
      font-family:Avenir LT Std 65 Medium;
      font-weight:600;
    }

    .Container_2_Text_Body{
      font-size:12px;
      margin-top:1rem;
      font-family:Avenir LT Std 65 Medium;
      font-weight:600;
    }

    .Container_2_Image{
      width:20rem;
      object-fit: contain;
    }  

    .Container_3{
      width:100%;
      height:26rem;
      margin-top:2rem;
      display:flex; 
      flex-direction: column;
    }
  
  }