.navbar {
  height: 15vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  position: sticky;
  top: 0;
  z-index: 500;
  /* cursor: pointer; */
}

.emptyDiv{
flex: 1;
}

.emptyDiv1{
  flex: 1;
  }

.nav-logo {
  flex:3;
}

.nav-links {
  flex:2;
  text-align: right;
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 1rem;
  list-style: none;
}

.right {
  flex: 1;
  /* margin-left: auto; */
  padding-top: 30px;
}

.nav-link {
  text-decoration: none;
  color: #000000;
  transition: 0.3s all;
}

.nav-link:hover, .nav-link:focus {
  color: #d3a625;
}


.nav-log {
  list-style: none;
  padding-top: 30px;

}
.nav-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

.nav-Image{
  object-fit:contain;
  width:100%;
}

.instagram-icon {
  color: #8d6f1a;
  height: 50px;
}

.instagram-icon:hover {
  color: #d3a625;
}


@media screen and (max-width: 1250px) and (min-width: 630px) {
  .right {
    width: auto;
  }
}

@media screen and (max-width: 630px) {
  .navbar {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 900;
    justify-content: space-between;
    /* cursor: pointer; */
  }

  .emptyDiv{
    flex: 0;
    }

    .emptyDiv1{
      flex: 0;
      }


  .nav-links {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    width: 100% ;
    top: 80px;
    left: -150%;
    transition: 0.2s all;
  }

  .nav-links.active {
    background: white;
    left: -10%;
  }

  .nav-item {
    border-bottom: 1px solid #d3a625;
    margin-left: -10%;
    height: 40px;
  }

  .nav-icon {
    display: flex;
    margin-top: 1rem;
  }

  .nav-Image{
    object-fit:contain;
    width:80%;
  }

  .right {
    width: 0px;
    margin-left: 0px;
    display: none;
  }
}
