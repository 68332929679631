.react-calendar {
  /*width: 100%;*/
  width: 100%;
  line-height: 12px;
}
.react-calendar--doubleView {
  width: 100%;

}
.react-calendar--doubleView .react-calendar__viewContainer {
  /* display: flex; */
  /* margin: -0.5em; */
  
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  /* width: 80%; */
  /* margin: 0.5em; */
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0 auto;
  /* padding: 10px; */
  border: none;
  /* width: 70px;
  height: 70px; */
  width: 3rem;
  height: 3rem;
  outline: none;
  font-family: "AvenirMedium";
  font-size: 16px;
}
.react-calendar button:enabled:hover {
  margin: 0 auto;
  padding: 10px;
  cursor: pointer;
}
.react-calendar__navigation {
  height: 2em;
  margin-bottom: 1em;
  font-family: "AvenirMedium";
  font-size: 24px;
}
.react-calendar__navigation button {
  width: 3em;
  height: auto;
  background: none;
  color: white;
  font-family: "AvenirMedium";
  font-size: 24px;
}
/* .react-calendar__navigation button:enabled:hover, */
/* .react-calendar__navigation button:enabled:focus {
  background-color: #52330d;
  border-radius: 100%;
} */
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  /* font-size: 0.75em; */
  font-size: 16px;
  color: white;
  font-family: "AvenirMedium";
  /* font-size: 24px; */
}
.react-calendar__month-view__weekdays__weekday {
  /* padding: 0.5em; */
  /* color: white; */
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
  /* color: white; */
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  /* 0.75em; */
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  /* width: 70px;
  height: 70px; */
  width: 69px;
  height: 69px;
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
  /* width: 70px;
  height: 70px; */
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  /* width: 70px;
  height: 70px; */
  width: 69px;
  height: 69px;
}
.react-calendar__tile {
  /* max-width: 100%; */

  text-align: center;
  background: none;
  border-radius: 100%;
  margin: auto auto;
  /* color: white; */
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
  color: black;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #52330d;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #52330d;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
