.h1 {
    /* // fontSize:  150% ; */
    /* // padding:  20px 0 ; */
    color:  #52330D ;
    font-family: Avenir LT Std 45 Book;
    font-weight: 600;
    text-align:  left ;
    font-size: 28px;
    padding-left: 20px ;
    /* // marginRight: 200px ; */
    /* // float: left ; */
    border: none;
    
}  

.pst{
  color: #4E70FF;
  font-family: Avenir LT Std 45 Book;
  font-weight: 600;
  font-size: 14px;
  margin-top: 1rem;
  /* margin-left: 5rem; */
}

.calanderHeader{
    text-align:  center ;
    font-size: 42px;
    font-family: Avenir LT Std 65 Medium ;
    font-weight: bolder;
    /* // fontSize: 60px/82px ; */
    /* // font:  normal normal medium 54px/74px Avenir ; */
    color:  #52330D ;
    padding:  20px 0 ;
    /* border: none; */
}
.leftTitle{
    margin-top: -80px ;
}
.selectTime {
   
    font-size:  42px ;
    margin-top: 3rem;
    color:  #52330D ;
    font-family: Avenir LT Std 45 Book ;
    font-weight: 600;
    text-align: center;
}
.container {
    /* margin:  auto ; */
    width:  100% ;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
.button {
    /* // buttons for times */
    background-color:  #F6A833 ;
    border:  none ;
    color:  white ;
    padding:  15px 100px ;
    text-align:  center ;
    text-decoration:  none ;
    display:  block ;
    font-size:  20px ;
    border-radius:  50px ;
    margin:  0 auto ;
     
}
.button.hover  {
    background:  #52330D ;
    color:  #F6A833 ;
  }

.buttonDisable {
    /* // comfirm button */
    background-color:  #F6A833 ;
    border:  none ;
    color:  white ;
    padding:  15px 100px ;
    text-align:  center ;
    text-decoration:  none ;
    display:  block ;
    font-size:  20px ;
    border-radius:  50px ;
    margin:  0 auto ;
    opacity:  50% ;
  }
  .inputRow {
    display:  flex ;
    justify-content:  space-between ;
  }
  .date {
    /* // date and time under comfirm meeting */
    font-size:  52px ;
    font-family:  AvenirHeavy ;
  }
  .calendarTimeTable{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .calendarBox {
    width:  35% ; 
    /* // this makes the calander and timeslot take up half the box each */
    /* // padding:  50px ; */
    /* // padding:  10px ; */
    background-color:  #F6A833 ;
  }

  .timeslotBox {
    width: 35% ;
    top:0px;
    height: auto ;
    border:  2px solid #F6A833  ;
  }
  .center {
    margin:  0 auto ;
  }
  .timeslotButton {
    background-color:  #F6A833 ;
    border:  none ;
    color:  white ;
    padding:   20px 100px 20px 100px  ;
    text-align:  center ;
    text-decoration:  none ;
    font-size:  20px ;
    border-radius:  50px ;
    margin:  5px ;
     
  }
  .timeslotButton.focus  {
    background:  #52330D ;
    color:  #F6A833 ;
  }

  .duritionInput {
    background-color:  #FAD399 ;
    width:  50% ;
    height:  75px ;
    border:  none ;
    padding:  20px ;
    box-sizing:  border-box ;
    border-radius:  50px ;
    color:  #52330D ;
    font-size:  25px ;
    display:  inline-block ;
    margin:  5px ;
    outline:  none ;
  }
  .duritionInput.placeholder  {
    text-Overflow:  ellipsis !important ;
    color:  #52330D ;
  }

  .timeslotButtonBox {
    width:  100% ;
    height:  300px ;
    margin-top: 5rem;
    margin:  0 auto ;
    overflow-Y:  scroll ;
  }
  .timeslotButtonBox.-webkit-scrollbar  {
    width:  10px ;
    /* // border:  1px solid black ; */
    border-radius:  20px ;
    padding:  1px 0 ;
    background-color:  white ;
  }
  .timeslotButtonBox.-webkit-scrollbar-thumb {
    border:  1px solid #000 ;
    border-radius:  20px ;
    padding:  1px 0 ;
    background-color:  #52330D ;
  }

  .ContainerContact{
    box-shadow: 0 0 18px #757575;
    background-color: white;
    width: 70%;
    height: auto;
    margin-bottom: 5rem;
    margin-Top: 5rem; 
  }

  .container_text{
    font-size: 38px;
    font-family:Avenir LT Std 65 Medium;
    font-weight: 600;
    margin-top: 1rem;
  }

  .Container_Name{
    width: 61%;
    padding: 20px;
    margin: 5px 5px;
    font-family:Avenir LT Std 65 Medium;
  }

  .Container_Phone{
    width: 30%;
    padding: 20px;
    margin: 5px 5px;
    font-family:Avenir LT Std 65 Medium;
  }

  .Container_Message{
    width: 61%;
    padding: 20px;
    margin: 5px 5px;
  }
  @media screen and (max-width: 1850px) and (min-width: 1600px) {

    .calendarBox {
      width:  25% ; 
      background-color:  #F6A833 ;
    }
  
    .timeslotBox {
      width: 25% ;
      top:0px;
      height: auto ;
      border:  2px solid #F6A833  ;
    }

  }

@media (max-width:980px){
  .h1 {
    /* // fontSize:  150% ; */
    /* // padding:  20px 0 ; */
    color:  #52330D;
    font-family:  AvenirHeavy ;
    text-align:  left ;
    font:  normal normal 900 28px/45px Avenir ;
    padding-left: 20px ;
    /* // marginRight: 200px ; */
    /* // float: left ; */
    
  }
  .pst{
    padding-left: 80px;
    color: #4E70FF;
    font: normal normal 900 14px/19px Avenir;
    margin-top: -20px;
  }
    
  .calanderHeader{
      text-align:  center ;
      font:  normal normal 60px/82px AvenirMedium ;
      /* // fontSize: 60px/82px ; */
      /* // font:  normal normal medium 54px/74px Avenir ; */
      color:  #52330D ;
      padding:  20px 0 ;
  }
  .leftTitle{
      margin-top: -80px ;
  }
  .selectTime {
      padding-top: 118px ;
      font-size:  42px ;
      color:  #52330D ;
      font-family:  AvenirHeavy ;
  }
  .container {
      margin:  auto ;
      width:  100% ;
      
      /* // padding:  50px ; */
    }
  .button {
      /* // buttons for times */
      background-color:  #F6A833 ;
      border:  none ;
      color:  white ;
      padding:  15px 100px ;
      text-align:  center ;
      text-decoration:  none ;
      display:  block ;
      font-size:  20px ;
      border-radius:  50px ;
      margin:  0 auto ;
      
  }
  .button.hover  {
      background:  #52330D ;
      color:  #F6A833 ;
    }

  .buttonDisable {
      /* // comfirm button */
      background-color:  #F6A833 ;
      border:  none ;
      color:  white ;
      padding:  15px 100px ;
      text-align:  center ;
      text-decoration:  none ;
      display:  block ;
      font-size:  20px ;
      border-radius:  50px ;
      margin:  0 auto ;
      opacity:  50% ;
    }
    .inputRow {
      display:  flex ;
      justify-content:  space-between ;
    }
    .date {
      /* // date and time under comfirm meeting */
      font-size:  64px ;
      font-family:  AvenirHeavy ;
    }
    .calendarTimeTable {
      width:  100% ;
      /* // margin:  0 auto ; */
      border:  2px solid #F6A833  ;
    }
    .calendarBox {
      width:  50% ; 
      /* // this makes the calander and timeslot take up half the box each */
      /* // padding:  50px ; */
      /* // padding:  10px ; */
      background-color:  #F6A833 ;
      height: 350px ;
    }

    /* .timeslotBox {
      width:  50% ;
      height: 350px ;
    } */
    .center {
      margin:  0 auto ;
    }
    .timeslotButton {
      background-color:  #F6A833 ;
      border:  none ;
      color:  white ;
      padding:   20px 100px 20px 100px  ;
      text-align:  center ;
      text-decoration:  none ;
      font-size:  20px ;
      border-radius:  50px ;
      margin:  5px ;
      
    }
    .timeslotButton.focus  {
      background:  #52330D ;
      color:  #F6A833 ;
    }

    .duritionInput {
      background-color:  #FAD399 ;
      width:  50% ;
      height:  75px ;
      border:  none ;
      padding:  20px ;
      box-sizing:  border-box ;
      border-radius:  50px ;
      color:  #52330D ;
      font-size:  25px ;
      display:  inline-block ;
      margin:  5px ;
      outline:  none ;
    }
    .duritionInput.placeholder  {
      text-Overflow:  ellipsis !important ;
      color:  #52330D ;
    }

    .timeslotButtonBox {
      width:  100% ;
      height:  400px ;
      margin:  0 auto ;
      overflow-Y:  scroll ;
    }
    .timeslotButtonBox.-webkit-scrollbar  {
      width:  10px ;
      /* // border:  1px solid black ; */
      border-radius:  20px ;
      padding:  1px 0 ;
      background-color:  white ;
    }
    .timeslotButtonBox.-webkit-scrollbar-thumb {
      border:  1px solid #000 ;
      border-radius:  20px ;
      padding:  1px 0 ;
      background-color:  #52330D ;
    }
}


@media (max-width:750px){
  .h1 {
    font:  normal normal 900 18px/40px Avenir ;
    padding-left: 20px ;
  }
    
  .pst{
    padding-left: 120px;
    color: #4E70FF;
    font: normal normal 900 12px/18px Avenir;
    margin-top: -20px;
  }

  .calanderHeader{
      font:  normal normal 45px/72px AvenirMedium ;
      padding:  20px 0 ;
  }
  .leftTitle{
      margin-top: -50px ;
  }
  .selectTime {
      padding-top: 118px ;
      font-size:  42px ;
      color:  #52330D ;
      font-family:  AvenirHeavy ;
  }
  .container {
      margin:  auto ;
      width:  100% ;
      
      /* // padding:  50px ; */
    }
  .button {
      /* // buttons for times */
      background-color:  #F6A833 ;
      border:  none ;
      color:  white ;
      padding:  15px 100px ;
      text-align:  center ;
      text-decoration:  none ;
      display:  block ;
      font-size:  20px ;
      border-radius:  50px ;
      margin:  0 auto ;
      
  }
  .button.hover  {
      background:  #52330D ;
      color:  #F6A833 ;
    }

  .buttonDisable {
      /* // comfirm button */
      background-color:  #F6A833 ;
      border:  none ;
      color:  white ;
      padding:  15px 100px ;
      text-align:  center ;
      text-decoration:  none ;
      display:  block ;
      font-size:  20px ;
      border-radius:  50px ;
      margin:  0 auto ;
      opacity:  50% ;
    }
    .inputRow {
      display:  flex ;
      justify-content:  space-between ;
    }
    .date {
      /* // date and time under comfirm meeting */
      font-size:  40px ;
      font-family:  AvenirHeavy ;
    }
    .calendarTimeTable {
      width:  100% ;
      /* // margin:  0 auto ; */
      border:  2px solid #F6A833  ;
    }
    .calendarBox {
      width:  50% ; 
      /* // this makes the calander and timeslot take up half the box each */
      /* // padding:  50px ; */
      /* // padding:  10px ; */
      background-color:  #F6A833 ;
      height: 300px ;
    }

    /* .timeslotBox {
      width:  50% ;
      height: 300px ;
    } */
    .center {
      margin:  0 auto ;
    }
    .timeslotButton {
      background-color:  #F6A833 ;
      border:  none ;
      color:  white ;
      padding:   20px 100px 20px 100px  ;
      text-align:  center ;
      text-decoration:  none ;
      font-size:  20px ;
      border-radius:  50px ;
      margin:  5px ;
      
    }
    .timeslotButton.focus  {
      background:  #52330D ;
      color:  #F6A833 ;
    }

    .duritionInput {
      background-color:  #FAD399 ;
      width:  50% ;
      height:  75px ;
      border:  none ;
      padding:  20px ;
      box-sizing:  border-box ;
      border-radius:  50px ;
      color:  #52330D ;
      font-size:  25px ;
      display:  inline-block ;
      margin:  5px ;
      outline:  none ;
    }
    .duritionInput.placeholder  {
      text-Overflow:  ellipsis !important ;
      color:  #52330D ;
    }

    .timeslotButtonBox {
      width:  100% ;
      height:  400px ;
      margin:  0 auto ;
      overflow-Y:  scroll ;
    }
    .timeslotButtonBox.-webkit-scrollbar  {
      width:  10px ;
      /* // border:  1px solid black ; */
      border-radius:  20px ;
      padding:  1px 0 ;
      background-color:  white ;
    }
    .timeslotButtonBox.-webkit-scrollbar-thumb {
      border:  1px solid #000 ;
      border-radius:  20px ;
      padding:  1px 0 ;
      background-color:  #52330D ;
    }
}
  
@media (max-width:650px){
  .h1 {
    font:  normal normal 900 18px/40px Avenir ;
    padding-left: 0px ;
  }
    
  .pst{
    padding-left: 0px;
    color: #4E70FF;
    font: normal normal 900 12px/18px Avenir;
    margin-top: 1rem;
  }

  .calanderHeader{
      /* font:  normal normal 40px/72px AvenirMedium ;
      padding:  20px 0 ; */
  }
  .leftTitle{
      margin-top: -50px ;
  }
  .selectTime {
      padding-top: 0px ;
      font-size:  42px ;
      color:  #52330D ;
      font-family:  AvenirHeavy ;
  }
  .container {

      margin:  auto ;
      width:  100% ;
      
      /* // padding:  50px ; */
    }
  .button {
      /* // buttons for times */
      background-color:  #F6A833 ;
      border:  none ;
      color:  white ;
      padding:  15px 100px ;
      text-align:  center ;
      text-decoration:  none ;
      display:  block ;
      font-size:  20px ;
      border-radius:  50px ;
      margin:  0 auto ;
      
  }
  .button.hover  {
      background:  #52330D ;
      color:  #F6A833 ;
    }

  .buttonDisable {
      /* // comfirm button */
      background-color:  #F6A833 ;
      border:  none ;
      color:  white ;
      padding:  15px 100px ;
      text-align:  center ;
      text-decoration:  none ;
      display:  block ;
      font-size:  20px ;
      border-radius:  50px ;
      margin:  0 auto ;
      opacity:  50% ;
    }
    .inputRow {
      display:  flex ;
      justify-content:  space-between ;
    }
    .date {
      /* // date and time under comfirm meeting */
      font-size:  40px ;
      font-family:  AvenirHeavy ;
    }
    .calendarTimeTable {
      width:  80% ;
      /* // margin:  0 auto ; */
      border:  2px solid #F6A833  ;
    }
    .calendarBox {
      width:  50% ; 
      /* // this makes the calander and timeslot take up half the box each */
      /* // padding:  50px ; */
      /* // padding:  10px ; */
      background-color:  #F6A833 ;
      height: 300px ;
    }

    /* .timeslotBox {
      width:  50% ;
      height: 300px ;
    } */
    .center {
      margin:  0 auto ;
    }
    .timeslotButton {
      background-color:  #F6A833 ;
      border:  none ;
      color:  white ;
      padding:   20px 100px 20px 100px  ;
      text-align:  center ;
      text-decoration:  none ;
      font-size:  20px ;
      border-radius:  50px ;
      margin:  5px ;
      
    }
    .timeslotButton.focus  {
      background:  #52330D ;
      color:  #F6A833 ;
    }

    .duritionInput {
      background-color:  #FAD399 ;
      width:  50% ;
      height:  75px ;
      border:  none ;
      padding:  20px ;
      box-sizing:  border-box ;
      border-radius:  50px ;
      color:  #52330D ;
      font-size:  25px ;
      display:  inline-block ;
      margin:  5px ;
      outline:  none ;
    }
    .duritionInput.placeholder  {
      text-Overflow:  ellipsis !important ;
      color:  #52330D ;
    }

    .timeslotButtonBox {
      width:  100% ;
      height:  400px ;
      margin:  0 auto ;
      overflow-Y:  scroll ;
    }
    .timeslotButtonBox.-webkit-scrollbar  {
      width:  10px ;
      /* // border:  1px solid black ; */
      border-radius:  20px ;
      padding:  1px 0 ;
      background-color:  white ;
    }
    .timeslotButtonBox.-webkit-scrollbar-thumb {
      border:  1px solid #000 ;
      border-radius:  20px ;
      padding:  1px 0 ;
      background-color:  #52330D ;
    }
  
}
  
@media (max-width:550px){

  .ContainerContact{
    box-shadow: 0 0 18px #757575;
    background-color: white;
    width: 90%;
    height: auto;
    margin-Top: 5rem; 
  }

  .calendarTimeTable{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .calendarBox {
    width:  100% ; 
    padding-bottom: 5rem;
    /* // this makes the calander and timeslot take up half the box each */
    /* // padding:  50px ; */
    /* // padding:  10px ; */
    background-color:  #F6A833 ;
  }

  .timeslotBox {
    width: 100% ;
    height: auto ;
    border:  2px solid #F6A833  ;
    /* // padding:  20px ; */
  }

  .container_text{
    font-size: 32px;
    font-family: Avenir LT Std 45 Book;
    font-weight: 600;
    margin-top: 1rem;
    text-align: center;
  }

  .date {
    font-size:  42px ;
    font-family:  AvenirHeavy ;
    text-align: center;
  }

  
  .Container_Name{
    width: 80%;
  }

  
  .Container_Phone{
    width: 80%;
    padding: 20px;
    margin: 5px 5px;
  }
  
  .Container_Message{
    width: 80%;
    padding: 20px;
    margin: 5px 5px;
  }
 
}
  
@media (max-width:450px){

}
  