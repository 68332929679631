

 /* Default Screen Size */
  .HomeContainer{
    display:flex;
    align-items:center; 
    flex-direction:column;
  }

  .BoxContainer_1{
    width:60%;
    height:auto;
    padding-bottom: 5rem;
    margin-top:5rem;
    background-color:white;
    display:flex;
    flex-direction: column;
    box-shadow:0 0 18px #757575; 
  }

  .BoxContainer_1_Text{
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items: center;
  flex:1;
  }

  .BoxContainer_1_Text_Header{
    color:#F6A833;
    /* font-size:54px;
    font-family:Libre Baskerville;
    font-weight:bold; */

    font-size:54px;
    /* color:white; */
    font-family:Libre Baskerville;
    font-weight:bold;
    text-align: center;
    margin-top: 2rem;
  }

  .BoxContainer_1_Text_and_Image{
    display: flex;
  }

  .BoxContainer_1_Text_Body{
    font-size:32px;
    margin-top:2rem;
    font-family:Avenir LT Std 65 Medium;
    font-weight:400;
    padding-left: 10%;
  }

  .BoxContainer_1_Text_Button{
    margin-top: 2rem;
    font-size:22px;
    height: 3.5rem;
    color:#52330D;
    border: 3px solid #52330D;
    background-color: #ffffff;
    border-radius:24px;
    font-family:Avenir LT Std 45 Book;
    font-weight:bold;
    text-transform: none;
    cursor: pointer;
  }

  .BoxContainer_1_Image{
    flex:1;
    display:flex;
    align-items:center;
    padding:2rem;
  }

  .BoxContainer_2{
    width:60%;
    height:6rem;
    margin-top:5rem;
    font-size:42px; 
    font-family:Libre Baskerville;
    font-weight:bold;
    text-align:center; 
  }

  .BoxContainer_3{
     width:60%;
     height:52rem;
     margin-top:5rem;
     background-color:#F6A833;
     display:flex;
     flex-direction:column;
     box-shadow:0 0 18px #757575; 
  }

  .BoxContainer_3_Text{
    display:flex;
    flex-direction:column;
    align-items:center;
    padding-left:6rem;
    padding-right:6rem;
    padding-top:3rem;
    padding-bottom:3rem;
  }
   .BoxContainer_3_Text_Header{
    font-size:38px;
    font-family:Libre Baskerville;
    font-weight:bold;
    color: white;
   }

   .BoxContainer_3_Text_Body{
    font-size:18px;
    color:white;
    text-align:center;
    margin-top:2rem;
    font-family:Avenir LT Std 65 Medium;
   }

   .BoxContainer_3_Text_Image{
    /* background-color: white; */
    display:flex;
    justify-content:center;
   }
   .BoxContainer_3_Text_ImageSize_1{
    width:100%;
    height:15rem;    
   }
   .BoxContainer_3_Text_ImageSize_2{
    width:100%;
    height:30rem;
   }

   .BoxContainer_3_Text_Button{
    font-family:Avenir LT Std 45 Book;
    text-transform:none;
    font-size:20px;
    padding: 1rem;
    margin-top:2rem;
    border:3px solid #FFFFFF;
    color: #ffffff;
    background-color: #F6A833;
    border-radius:42px;
    cursor: pointer;
   }

   .BoxContainer_4{
    width:60%;
    height:52rem;
    margin-top:5rem;
    background-color:#52330D;
    display:flex;
    flex-direction:column;
    box-shadow:0 0 18px #757575; 
 }

 .BoxContainer_4_Text{
   display:flex;
   flex-direction:column;
   align-items:center;
   padding-left:6rem;
   padding-right:6rem;
   padding-top:3rem;
   padding-bottom:3rem;
 }

 
 .BoxContainer_4_Text_Button{
  font-family:Avenir LT Std 45 Book;
  text-transform:none;
  margin-top:2rem;
  font-size:20px;
  padding: 1rem;
  border:3px solid #FFFFFF;
  color: #ffffff;
  background-color: #52330D;
  border-radius:36px;
  cursor: pointer;
 }

 .BoxContainer_5{
  width:60%;
  height:auto;
  margin-top:5rem;
  background-color:#FFF9F0;
  display:flex;
  flex-direction:column;
  margin-bottom: 5rem;
  box-shadow:0 0 18px #757575 
 }

 .BoxContainer_5_Text{
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-left:6rem;
  padding-right:6rem;
  padding-top:3rem;
  padding-bottom:3rem;
 }

 .BoxContainer_5_Text_Header{
  font-size:38px;
  color:#52330D;
  font-family:Libre Baskerville;
  font-weight:bold;
 }

 .BoxContainer_5_Text_Body{
  font-size:18px;
  color:#52330D;
  text-align:center;
  /* padding-left:4rem;
  padding-right: 4rem;
  padding-top: 2rem;
  padding-bottom: 4rem; */
  font-family:Avenir LT Std 65 Medium;
  font-weight:600;
  margin-top: 2rem;
 }

 .BoxContainer_5_Text_Tail{
  font-size:18px;
  color:#52330D;
  text-align:center;
  margin-top: 2rem;
  font-family:Avenir LT Std 65 Medium;
  font-weight:600;
 }

 .BoxContainer_6{
  width:60%;
  height:28rem;
  margin-top:5rem;
  background-color:#F6A833;
  display:flex;
  flex-direction:column;
  box-shadow:0 0 18px #757575
 }

.BoxContainer_6_Text_Header{
 font-size:38px;
 color:white;
 margin-top:2rem;
 font-family:Libre Baskerville;
 font-weight:600;
 text-align: center;
}

.BoxContainer_6_Text_Body{
  display:flex;
  justify-content:space-evenly;
  color:white;
  margin-top:2rem;
  font-size:24px;
  font-family:Avenir LT Std 65 Medium;
  font-weight:600
} 


.BoxContainer_6_Text_Button{
  font-size:20px;
  font-family:Avenir LT Std 45 Book;
  text-transform:none;
  font-weight:500;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top:2rem;
  cursor: pointer;
  border:3px solid #FFFFFF;
  color:#FFFFFF;
  background-color: #F6A833;
  border-radius:42px;
  cursor: pointer;
}

.BoxContainer_6_Text_Body_Margin{
  /* margin-left:10rem */
}

.BoxContainer_7_Text_Body{
  font-size:18px;
  color:#52330D;
  text-align:center;
  font-family:Avenir LT Std 65 Medium;
  font-weight:600;


 }


 /* Big Screen Size */
  @media screen and (max-width: 1175px) {

    .BoxContainer_1{
      /* width:620px;
      height:400px; */
      width:60%;
      height:auto;
      margin-top:5rem;
      background-color:white;
      display:flex;
      box-shadow:0 0 18px #757575; 
    }

    .BoxContainer_1_Text{
      display:flex;
      justify-content:flex-start;
      flex-direction:column;
      flex:1;
      padding:2rem;
      }
    
      .BoxContainer_1_Text_Header{
        color:#F6A833;
      

        font-size:54px;
        font-family:Libre Baskerville;
        font-weight:bold;
      }
    
      .BoxContainer_1_Text_Body{
        font-size:18px;
        margin-top:2rem;
        font-family:Avenir LT Std 65 Medium;
        font-weight:400;
      }

      .BoxContainer_1_Text_Button{
        margin-top: 2rem;
        font-size:8px;
        height: 2rem;
        color:#52330D;
        border: 3px solid #52330D;
        border-radius:24px;
        font-family:Avenir LT Std 45 Book;
        font-weight:bold;
        text-transform: none;
        cursor: pointer;

      }

      .BoxContainer_1_Image{
        flex:1;
        display:flex;
        align-items:center;
        padding:2rem;
      
       }

       .BoxContainer_2{
        /* width:620px;
        height:5rem; */
        width:60%;
        height:auto;
        margin-top:3rem;
        font-size:18px; 
        font-family:Libre Baskerville;
        font-weight:bold;
        text-align:center; 
      }
  
      .BoxContainer_3{
        /* width:620px;
        height:auto; */
        width:60%;
        height:auto;
        margin-top:1rem;
        background-color:#F6A833;
        display:flex;
        flex-direction:column;
        box-shadow:0 0 18px #757575; 
     }
 
   .BoxContainer_3_Text{
     display:flex;
     flex-direction:column;
     align-items:center;
     padding-left:1rem;
     padding-right:1rem;
     padding-top:1rem;
     padding-bottom:1rem;
   }
    .BoxContainer_3_Text_Header{
     font-size:22px;
     color:white;
     font-family:Libre Baskerville;
     font-weight:bold;
    }
 
    .BoxContainer_3_Text_Body{
     font-size:12px;
     color:white;
     text-align:center;
     font-family:Avenir LT Std 65 Medium;
    }
 
    .BoxContainer_3_Text_Image{
     display:flex;
     justify-content:center;
     padding-left: 1rem;
     padding-right: 1rem;

    }
    .BoxContainer_3_Text_ImageSize_1{
     width:100%;
     height:7.5rem;
    }
    .BoxContainer_3_Text_ImageSize_2{
     width:100%;
     height:15rem;
    }

   .BoxContainer_3_Text_Button{
    font-family:Avenir LT Std 45 Book;
    text-transform:none;
    font-size:12px;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    height: 2rem;
    border:3px solid #FFFFFF;
    color: #ffffff;
    background-color: #F6A833;
    cursor: pointer;
    border-radius:36px;
   }

   .BoxContainer_4{
    /* width:620px;
    height:auto; */
    width:60%;
    height:auto;
    margin-top:1rem;
    background-color:#52330D;
    display:flex;
    flex-direction:column;
    box-shadow:0 0 18px #757575; 
 }
   .BoxContainer_4_Text{
    display:flex;
    flex-direction:column;
    align-items:center;
    padding-left:1rem;
    padding-right:1rem;
    padding-top:1rem;
    padding-bottom:1rem;
  }

  .BoxContainer_4_Text_Button{
    font-family:Avenir LT Std 45 Book;
    text-transform:none;
    font-size:12px;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    border:3px solid #FFFFFF;
    color: #ffffff;
    background-color: #52330D;
    cursor: pointer;
    border-radius:36px;
   }

   
 .BoxContainer_5{
  /* width:620px;
  height:auto; */
  width:60%;
  height:auto;
  margin: 0;
  margin-top:3rem;
  background-color:#FFF9F0;
  display:flex;
  flex-direction:column;
  box-shadow:0 0 18px #757575 
 }

 .BoxContainer_5_Text{
  display:flex;
  flex-direction:column;
  padding-left:0rem;
  padding-right:0rem;
  padding-top:0rem;
  padding-bottom:0rem;
 }

 .BoxContainer_5_Text_Header{
  font-size:24px;
  color:#52330D;
  margin-top: 2rem;
  font-family:Libre Baskerville;
  font-weight:bold
 }

 .BoxContainer_5_Text_Body{
  font-size:12px;
  color:#52330D;
  margin-top: -1rem;
  text-align:center;
  font-family:Avenir LT Std 65 Medium;
  font-weight:600;
 }

 .BoxContainer_5_Text_Tail{
  font-size:12px;
  color:#52330D;
  margin-top: -1rem;
  text-align:center;
  font-family:Avenir LT Std 65 Medium;
  font-weight:600;
  margin-bottom: 2rem;
 }

 
 .BoxContainer_6{
  /* width:620px;
  height:18rem; */
  width:60%;
  height:auto;
  margin-top:3rem;
  background-color:#F6A833;
  align-items:center;
  display:flex;
  flex-direction:column;
  box-shadow:0 0 18px #757575
 }

.BoxContainer_6_Text_Header{
 font-size:24px;
 color:white;
 margin-top:2rem;
 font-family:Libre Baskerville;
 font-weight:600;
}

.BoxContainer_6_Text_Body{
  display:flex;
  justify-content:space-between;
  color:white;
  margin-top:2rem;
  font-size:12px;
  font-family:Avenir LT Std 65 Medium;
  font-weight:600;
} 


.BoxContainer_6_Text_Button{
  font-size:18px;
  font-family:Avenir LT Std 45 Book;
  text-transform:none;
  font-weight:600;
  height: 3rem;
  margin-top:2rem;
  border:3px solid #FFFFFF;
  background-color: #F6A833;
  color:#FFFFFF;
  border-radius:24px;
  cursor: pointer;
}

.BoxContainer_6_Text_Body_Margin{
  margin-left:3rem
}


.BoxContainer_7_Text_Body{
  font-size:12px;
  color:#52330D;
  text-align:center;
  padding:2rem;
  font-family:Avenir LT Std 65 Medium;
  font-weight:600;
 } 
}

 /* Medium Screen Size */
 @media screen and (max-width: 1175px) and (min-width: 630px) {

  .BoxContainer_1{
    width:75%;
    height:auto;
    margin-top:5rem;
    background-color:white;
    display:flex;
    flex-direction: column;
    box-shadow:0 0 18px #757575; 
  }

  .BoxContainer_1_Text{
    display:flex;
    justify-content:flex-start;
    flex-direction:column;
    flex:2;
    padding:2rem;
    }

    .BoxContainer_1_Text_and_Image{
      display: flex;
      flex-direction: column;
    }
  
    .BoxContainer_1_Text_Header{
      color:#F6A833;
      /* font-size:16px;
      font-family:Libre Baskerville;
      font-weight:bold; */

      font-size:32px;
      /* color:white; */
      font-family:Libre Baskerville;
      font-weight:bold;
      text-align: center;
    }
  
    .BoxContainer_1_Text_Body{
      font-size:18px;
      margin-top:2rem;
      font-family:Avenir LT Std 65 Medium;
      font-weight:400;
      text-align: center;
      padding-left: 0%;
    }

    .BoxContainer_1_Text_Button{
      margin-top: 2rem;
      font-size:16px;
      color:#52330D;
      border: 3px solid #52330D;
      border-radius:24px;
      font-family:Avenir LT Std 45 Book;
      font-weight:bold;
      cursor: pointer;
      text-transform: none;
    }

    .BoxContainer_1_Image{
      flex:1;
      display:flex;
      align-items:center;
      padding:2rem;
    
     }

     .BoxContainer_2{
      width:75%;
      height:auto;
      margin-top:3rem;
      font-size:24px; 
      font-family:Libre Baskerville;
      font-weight:bold;
      text-align:center; 
    }

    .BoxContainer_3{
      width:75%;
      height:auto;
      margin-top:1rem;
      background-color:#F6A833;
      display:flex;
      flex-direction:column;
      box-shadow:0 0 18px #757575; 
   }

 .BoxContainer_3_Text{
   display:flex;
   flex-direction:column;
   align-items:center;
   padding-left:1rem;
   padding-right:1rem;
   padding-top:1rem;
   padding-bottom:1rem;
 }
  .BoxContainer_3_Text_Header{
   font-size:22px;
   color:white;
   font-family:Libre Baskerville;
   font-weight:bold;
   text-align: center;
  }

  .BoxContainer_3_Text_Body{
   font-size:12px;
   color:white;
   text-align:center;
   font-family:Avenir LT Std 65 Medium;
  }

  .BoxContainer_3_Text_Image{
   display:flex;
   flex-direction: column;
   justify-content:flex-start;
   padding-left: 5%;
   padding-right: 5%;

  }
  .BoxContainer_3_Text_ImageSize_1{
   width:100%;
  object-fit: cover;
  }
  .BoxContainer_3_Text_ImageSize_2{
   width:100%;
   object-fit: contain;
   height: auto;
  }

 .BoxContainer_3_Text_Button{
  font-family:Avenir LT Std 45 Book;
  text-transform:none;
  font-size:12px;
  height: auto;
  border:3px solid #FFFFFF;
  color: #ffffff;
  background-color: #F6A833;
  cursor: pointer;
  margin-bottom: 2rem;
  border-radius:36px;
 }

 .BoxContainer_4{
  width:75%;
  height:auto;
  margin-top:1rem;
  background-color:#52330D;
  display:flex;
  flex-direction:column;
  box-shadow:0 0 18px #757575; 
}
 .BoxContainer_4_Text{
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-left:1rem;
  padding-right:1rem;
  padding-top:1rem;
  padding-bottom:1rem;
}

.BoxContainer_4_Text_Button{
  font-family:Avenir LT Std 45 Book;
  text-transform:none;
  font-size:12px;
  border:3px solid #FFFFFF;
  color: #ffffff;
  cursor: pointer;
  background-color: #52330D;
  border-radius:36px;
  margin-bottom: 2rem;
 }

 
.BoxContainer_5{
  width:75%;
  height:auto;
margin: 0;
margin-top:3rem;
background-color:#FFF9F0;
display:flex;
margin-bottom: 5rem;
flex-direction:column;
box-shadow:0 0 18px #757575 
}

.BoxContainer_5_Text{
display:flex;
flex-direction:column;
padding: 5%;
}

.BoxContainer_5_Text_Header{
color:#52330D;
margin-top: 2rem;
font-weight:bold;
font-size:22px;
font-family:Libre Baskerville;
font-weight:bold;
text-align: center;
}

.BoxContainer_5_Text_Body{
font-size:12px;
color:#52330D;
text-align:center;
font-family:Avenir LT Std 65 Medium;
font-weight:600;
margin-top: 1rem;
}

.BoxContainer_5_Text_Tail{
font-size:12px;
margin-top: 1rem;
color:#52330D;
text-align:center;
font-family:Avenir LT Std 65 Medium;
font-weight:600;
}


.BoxContainer_6{
  width:75%;
  height:auto;
margin-top:3rem;
background-color:#F6A833;
display:flex;
flex-direction:column;
box-shadow:0 0 18px #757575;
}

.BoxContainer_6_Text_Header{
font-size:24px;
color:white;
margin-top:2rem;
font-family:Libre Baskerville;
font-weight:600;
}

.BoxContainer_6_Text_Body{
display:flex;
justify-content:space-evenly;
color:white;
margin-top:2rem;
font-size:12px;
font-family:Avenir LT Std 65 Medium;
font-weight:600;
} 


.BoxContainer_6_Text_Button{
font-size:12px;
font-family:Avenir LT Std 45 Book;
text-transform:none;
font-weight:600;
height:auto;
margin-top:2rem;
border:3px solid #FFFFFF;
background-color: #F6A833;
color:#FFFFFF;
border-radius:24px;
cursor: pointer;
margin-bottom: 2rem;

}


.BoxContainer_7_Text_Body{
font-size:12px;
color:#52330D;
text-align:center;
font-family:Avenir LT Std 65 Medium;
font-weight:600;
margin-top: 1rem;
}
}

 /* Small Screen Size */
@media screen and (max-width: 630px) {

  .BoxContainer_1{
    width:90%;
    height:auto;
    margin-top:5rem;
    background-color:white;
    display:flex;
    flex-direction: column;
    box-shadow:0 0 18px #757575; 
  }

  .BoxContainer_1_Text{
    display:flex;
    justify-content:flex-start;
    flex-direction:column;
    flex:2;
    padding:2rem;
    }

    .BoxContainer_1_Text_and_Image{
      display: flex;
      flex-direction: column;
    }
  
    .BoxContainer_1_Text_Header{
      color:#F6A833;
      /* font-size:16px;
      font-family:Libre Baskerville;
      font-weight:bold; */

      font-size:32px;
      /* color:white; */
      font-family:Libre Baskerville;
      font-weight:bold;
      text-align: center;
    }
  
    .BoxContainer_1_Text_Body{
      font-size:18px;
      margin-top:2rem;
      font-family:Avenir LT Std 65 Medium;
      font-weight:400;
      text-align: center;
      padding-left: 0%;
    }

    .BoxContainer_1_Text_Button{
      margin-top: 2rem;
      font-size:16px;
      color:#52330D;
      border: 3px solid #52330D;
      border-radius:24px;
      font-family:Avenir LT Std 45 Book;
      font-weight:bold;
      cursor: pointer;
      text-transform: none;
    }

    .BoxContainer_1_Image{
      flex:1;
      display:flex;
      align-items:center;
      padding:2rem;
    
     }

     .BoxContainer_2{
      width:90%;
      height:auto;
      margin-top:3rem;
      font-size:24px; 
      font-family:Libre Baskerville;
      font-weight:bold;
      text-align:center; 
    }

    .BoxContainer_3{
      width:90%;  
      height:auto;
      margin-top:1rem;
      background-color:#F6A833;
      display:flex;
      flex-direction:column;
      box-shadow:0 0 18px #757575; 
   }

 .BoxContainer_3_Text{
   display:flex;
   flex-direction:column;
   align-items:center;
   padding-left:1rem;
   padding-right:1rem;
   padding-top:1rem;
   padding-bottom:1rem;
 }
  .BoxContainer_3_Text_Header{
   font-size:22px;
   color:white;
   font-family:Libre Baskerville;
   font-weight:bold;
   text-align: center;
  }

  .BoxContainer_3_Text_Body{
   font-size:12px;
   color:white;
   text-align:center;
   font-family:Avenir LT Std 65 Medium;
  }

  .BoxContainer_3_Text_Image{
   display:flex;
   flex-direction: column;
   justify-content:flex-start;
   padding-left: 5%;
   padding-right: 5%;

  }
  .BoxContainer_3_Text_ImageSize_1{
   width:100%;
  object-fit: cover;
  }
  .BoxContainer_3_Text_ImageSize_2{
   width:100%;
   object-fit: contain;
   height: auto;
  }

 .BoxContainer_3_Text_Button{
  font-family:Avenir LT Std 45 Book;
  text-transform:none;
  font-size:12px;
  height: auto;
  border:3px solid #FFFFFF;
  color: #ffffff;
  background-color: #F6A833;
  cursor: pointer;
  margin-bottom: 2rem;
  border-radius:36px;
 }

 .BoxContainer_4{
  width:90%;
  height:auto;
  margin-top:1rem;
  background-color:#52330D;
  display:flex;
  flex-direction:column;
  box-shadow:0 0 18px #757575; 
}
 .BoxContainer_4_Text{
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-left:1rem;
  padding-right:1rem;
  padding-top:1rem;
  padding-bottom:1rem;
}

.BoxContainer_4_Text_Button{
  font-family:Avenir LT Std 45 Book;
  text-transform:none;
  font-size:12px;
  border:3px solid #FFFFFF;
  color: #ffffff;
  cursor: pointer;
  background-color: #52330D;
  border-radius:36px;
  margin-bottom: 2rem;
 }

 
.BoxContainer_5{
width:90%;
height:auto;
margin: 0;
margin-top:3rem;
background-color:#FFF9F0;
display:flex;
margin-bottom: 5rem;
flex-direction:column;
box-shadow:0 0 18px #757575 
}

.BoxContainer_5_Text{
display:flex;
flex-direction:column;
padding: 5%;
}

.BoxContainer_5_Text_Header{
color:#52330D;
margin-top: 2rem;
font-weight:bold;
font-size:22px;
font-family:Libre Baskerville;
font-weight:bold;
text-align: center;
}

.BoxContainer_5_Text_Body{
font-size:12px;
color:#52330D;
text-align:center;
font-family:Avenir LT Std 65 Medium;
font-weight:600;
margin-top: 1rem;
}

.BoxContainer_5_Text_Tail{
font-size:12px;
margin-top: 1rem;
color:#52330D;
text-align:center;
font-family:Avenir LT Std 65 Medium;
font-weight:600;
}


.BoxContainer_6{
width:90%;
height:auto;
margin-top:3rem;
background-color:#F6A833;
display:flex;
flex-direction:column;
box-shadow:0 0 18px #757575;
}

.BoxContainer_6_Text_Header{
font-size:24px;
color:white;
margin-top:2rem;
font-family:Libre Baskerville;
font-weight:600;
}

.BoxContainer_6_Text_Body{
display:flex;
justify-content:space-evenly;
color:white;
margin-top:2rem;
font-size:12px;
font-family:Avenir LT Std 65 Medium;
font-weight:600;
} 


.BoxContainer_6_Text_Button{
font-size:12px;
font-family:Avenir LT Std 45 Book;
text-transform:none;
font-weight:600;
height:auto;
margin-top:2rem;
border:3px solid #FFFFFF;
background-color: #F6A833;
color:#FFFFFF;
border-radius:24px;
cursor: pointer;
margin-bottom: 2rem;

}


.BoxContainer_7_Text_Body{
font-size:12px;
color:#52330D;
text-align:center;
font-family:Avenir LT Std 65 Medium;
font-weight:600;
margin-top: 1rem;
}
}
  
